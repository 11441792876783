import React from "react";
import { graphql } from "gatsby";
import { Typography } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import rehypeReact from 'rehype-react';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import Layout from "../components/layout";
import Seo from "../components/seo";

deckDeckGoHighlightElement();

const useStyles = makeStyles(theme => ({
  container: {

  },
  title: {
    paddingTop: 30,
    paddingBottom: 10,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.875rem',
      paddingTop: 20,
      paddingBottom: 8,
    },
  },
  date: {
    paddingBottom: 10,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  tableOfContents: {
    paddingTop: 15,
    paddingBottom: 15,

    '& a': {
      fontFamily: 'Alef',
      fontSize: '1rem',
      fontWeight: 400,
      textAlign: 'left',
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  blogBody: {
    '& p': {
      paddingBottom: 20,

      /* remove some bottom padding when followed by a code block or list block */
      '& + deckgo-highlight-code, & + ol, & + ul': {
        marginTop: -10
      }
    },

    '& h1, & h2, & h3, & h4, & h5': {
      paddingTop: 10,
      paddingBottom: 10
    },

    '& h2': {
      color: theme.palette.primary.main,
      paddingTop: 40
    },

    '& ol, & ul': {
      fontSize: '1rem'
    },

    /* code blocks */
    '& deckgo-highlight-code': {
      marginBottom: 30,
      backgroundColor: theme.palette.secondary.main
    },

    /* inline code */
    '& code.language-text': {
      color: theme.palette.tertiary.light
    }
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    listStyle: 'none',
    padding: 0,
    marginTop: 75
  },
  navLink: {
    fontSize: '1rem',
  },
}));

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const classes = useStyles();
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      h1: ({ children }) => (<Typography variant="h1">{children}</Typography>),
      h2: ({ children }) => (<Typography variant="h2">{children}</Typography>),
      h3: ({ children }) => (<Typography variant="h3">{children}</Typography>),
      h4: ({ children }) => (<Typography variant="h4">{children}</Typography>),
      h5: ({ children }) => (<Typography variant="h5">{children}</Typography>),
      h6: ({ children }) => (<Typography variant="h6">{children}</Typography>),
      p: ({ children }) => (<Typography variant="body1">{children}</Typography>),
      a: Link
    },
  }).Compiler;

  return (
    <Layout location={location} title={post.frontmatter.title}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Container className={classes.container} maxWidth="md">
        <article>

          <header>
            <Typography
              variant="h1"
              className={classes.title}
            >
              {post.frontmatter.title}
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              color="textSecondary"
              className={classes.date}
            >
              {post.frontmatter.date}
            </Typography>
          </header>

          { post.tableOfContents &&
            <div className={classes.tableOfContents}>
              <Typography variant="h2">Table of Contents</Typography>
              <section
                dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
              />
          </div>
          }

          <section>
            <div className={classes.blogBody}>
              {renderAst(post.htmlAst)}
            </div>
          </section>

        </article>

        <nav>
          <ul className={classes.nav}>
            <li>
              {previous && (
                <Link
                  className={classes.navLink}
                  to={previous.fields.slug}
                  rel="prev"
                >
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link
                  className={classes.navLink}
                  to={next.fields.slug}
                  rel="next"
                >
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Container>
    </Layout>
  )
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
